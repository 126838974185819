<template>
  <b-card-actions
    ref="AttributesList"
    title="Atributos"
    action-refresh
    class="top-level"
    @refresh="updateRows({})"
  >
    <b-row align-v="start">

      <b-col md="4">
        <b-form-group>
          <label>Nome</label>
          <b-form-input
            v-model="newAttributeName"
            :disabled="state === 'loading'"
            placeholder="Nome do Atributo"
            type="text"
            class="d-inline-block"
            autocomplete="off"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-button
          class="float-right"
          variant="success"
          :disabled="state === 'loading'"
          @click="createAttribute"
        >
          Criar
        </b-button>
      </b-col>

    </b-row>

    <div>
      <b-card
        v-for="attribute in rows"
        :key="attribute.id"
        :class="`mb-1 list-row ${selected.includes(attribute.id) ? 'row-selected' : ''}`"
        @click="$router.push({ name: 'product-attributes', params: { id: attribute.id } })"
      >
        <b-row align-v="center">
          <b-col
            lg="6"
            cols="12"
          >
            {{ attribute.name }}

          </b-col>
          <b-col
            lg="6"
            cols="12"
            class="d-lg-block"
          >
            <b-row align-v="center">
              <b-col>
                <b-button
                  class="float-right"
                  variant="danger"
                  :disabled="state === 'loading'"
                  @click.stop="deleteAttributeType(attribute.id)"
                >
                  Deletar
                </b-button>
              </b-col>

            </b-row>
          </b-col>
        </b-row>
      </b-card>
    </div>

  </b-card-actions>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton, VBPopover, BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol, BCard, BForm,
  BFormCheckbox, BFormDatepicker,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
    BFormDatepicker,
    BFormCheckbox,
    BForm,
    BButton,
    BCardActions,
    BCard,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      quantity: 1,
      allSelected: false,
      allSelectState: false,
      selected: [],
      searchFilter: null,
      state: 'ready',
      dir: false,
      newAttributeName: '',
    }
  },
  computed: {
    rows() {
      return this.$store.state.attributes.attributeTypes
    },
  },
  watch: {
    allSelected(value) {
      if (value) {
        this.selected = this.$store.state.products.productsList.map(item => item.id)
        this.allSelectState = false
      } else if (this.allSelectState) {
        this.allSelectState = false
      } else {
        this.selected = []
      }
    },
    selected(value) {
      if (value.length !== this.$store.state.products.productsList.length) {
        this.allSelectState = true
        this.allSelected = false
      }
    },
  },
  mounted() {
    this.updateRows()
  },
  methods: {
    selectCard(id, unique = true) {
      const index = this.selected.indexOf(id)
      if (unique) {
        if (index > -1) {
          this.selected = []
        } else {
          this.selected = [id]
        }
      } else if (index > -1) {
        this.selected.splice(index, 1)
      } else {
        this.selected.push(id)
      }
    },
    updateRows() {
      this.state = 'loading'
      this.$refs.AttributesList.showLoading = true
      this.$store.dispatch('attributes/getAttributeTypes').finally(() => {
        this.selected = this.selected.filter(item => this.$store.state.attributes.attributeTypes.find(attribute => attribute.id === item))
        this.state = 'ready'
        this.$refs.AttributesList.showLoading = false
      })
    },
    createAttribute() {
      this.$store.dispatch('attributes/createAttributeTypes', {
        name: this.newAttributeName,
        slug: this.string_to_slug(this.newAttributeName),
        importance_order: 1,
      }).then(() => {
        this.updateRows()
      })
    },
    deleteAttributeType(id) {
      this.$store.dispatch('attributes/deleteAttributeType', id).then(() => {
        this.updateRows()
      })
    },
    string_to_slug(str) {
      str = str.replace(/^\s+|\s+$/g, '') // trim
      str = str.toLowerCase()

      // remove accents, swap ñ for n, etc
      const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
      const to = 'aaaaeeeeiiiioooouuuunc------'
      for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
      }

      str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-') // collapse dashes

      return str
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.card-body{
  padding-top: 0;
  padding-bottom: 0;
}

.list-row:hover {
  background: rgb(226, 226, 226);
}
.row-selected {
  background: #004259 !important;
  color: white;
}

.negative-stock {
  background: rgb(116, 0, 0) !important;
  color: white !important;
}

.flex-parent {
  display: flex;
  align-items: center;
  padding: 10px;
}

.long-and-truncated {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#reason{
  border-radius: 6px;
}
</style>
